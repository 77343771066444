<template>
  <component :is="props.tag" class="flex items-center">
    <template v-if="$slots.icon">
      <div class="shrink-0">
        <slot name="icon" />
      </div>
    </template>
    <template v-if="$slots.number">
      <div class="shrink-0">
        <div class="border border-neutral-200 aspect-[1/1] w-[30px] text-center text-lg rounded">
          <slot name="number" />
        </div>
      </div>
    </template>
    <div :class="[ {'pl-2 md:pl-3' : $slots.icon || $slots.number}, 'break-words grow' ]">
      <slot />
    </div>
  </component>
</template>
<script lang="ts" setup>
const props = defineProps({
  tag: {
    type: String,
    default: 'div'
  }
})
</script>
